function sortObjectAlphabetically(object: any) {
  if (typeof object === 'object' && object !== null) {
    return Object.keys(object)
      .sort()
      .reduce(
        (accumulator, key) => ({
          ...accumulator,
          [key]: object[key]
        }),
        {}
      );
  } else {
    return object;
  }
}

export { sortObjectAlphabetically };
