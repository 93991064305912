import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SessionService } from './session.service';
import { Logger } from './common/logger.service';
import { ListFilter } from '@parashift/shared/models';
import { sortObjectAlphabetically } from '@parashift/shared/utils';

export interface StoreFilterParams {
  allowMultipleInstances: boolean;
  identifier: string;
  filterType: string;
  field: string;
  value?: any;
}

export interface RestoreFilterParams {
  allowMultipleInstances: boolean;
  identifier: string;
  filterType: string;
  field?: string | undefined;
}

export interface Filter {
  type: string;
  field?: string;
  value: any;
  checked?: boolean;
}

const filtersDoNotResetPageNumber = ['page', 'group-by'];

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private _setFilter = new Subject();
  private _emitFilterChange: Subject<Filter> = new Subject();
  private _filterEvent = new BehaviorSubject(true);
  private _bypassFilter = new Subject();
  private _groupBy = new Subject();
  private _resetFilter = new Subject<boolean>();
  private _resetForeignFilter = new Subject<Filter>();
  private _listPageChanged = new Subject<void>();

  setFilter$ = this._setFilter.asObservable();
  filterChanged$ = this._emitFilterChange.asObservable();
  filterEvent$ = this._filterEvent.asObservable();
  byPassFilter$ = this._bypassFilter.asObservable();
  groupBy$ = this._groupBy.asObservable();
  resetFilter$ = this._resetFilter.asObservable();
  resetForeignFilter$ = this._resetForeignFilter.asObservable();
  listPageChanged$ = this._listPageChanged.asObservable();

  constructor(
    private sessionService: SessionService,
    private logger: Logger
  ) {}

  cleanUpListFilter(listFilter: ListFilter): ListFilter {
    if (listFilter.filter) {
      listFilter.filter = sortObjectAlphabetically(listFilter.filter);
      delete listFilter.filterUntouched;

      const entries = Object.entries(listFilter.filter);
      for (const [key, value] of entries) {
        if (listFilter.filter[key]) {
          if (Array.isArray(value) && (value.length === 0 || value.includes(''))) {
            delete listFilter.filter[key];
          }
        } else {
          delete listFilter.filter[key];
        }
      }
    }

    return listFilter;
  }

  setFilter(filter: Filter) {
    this._setFilter.next(filter);
    this._filterEvent.next(true);
  }

  resetFilter() {
    this._resetFilter.next(true);
  }

  resetForeignFilter(filter: Filter) {
    this._resetForeignFilter.next(filter);
  }

  emitFilterChange(filter: Filter) {
    this._emitFilterChange.next(filter);
    this._filterEvent.next(true);
  }

  bypassFilter(listFilter: ListFilter) {
    this._bypassFilter.next(listFilter);
  }

  groupBy(field: string) {
    this._groupBy.next(field);
  }

  listPageChanged() {
    this._listPageChanged.next();
  }

  storeFilter(params: StoreFilterParams) {
    if (!params.identifier) {
      this.logger.error('Please define a meta.identifier in your filter-columnn-config!');
      return;
    }

    const filters = this.sessionService.filters;

    if (typeof filters[params.identifier] !== 'object') {
      filters[params.identifier] = {};
    }

    // Usually filter changes also change the result set. So it makes sense to reset page number to 1
    // Exceptions should be listed here in filtersDoNotResetPageNumber constant
    if (!filtersDoNotResetPageNumber.includes(params.filterType)) {
      filters[params.identifier].page = { field: 'number', value: 1 };
    }

    if (params.allowMultipleInstances) {
      this.storeMultipleInstancesValue(params, filters);
    } else {
      this.storeSingleInstanceValue(params, filters);
    }
  }

  restoreFilter(params: RestoreFilterParams) {
    if (!params.identifier) {
      this.logger.error('Please define a meta.identifier in your filter-columnn-config!');
      return undefined;
    }

    const filters = this.sessionService.filters;

    if (Object.keys(filters).length > 0 && filters[params.identifier] && filters[params.identifier][params.filterType]) {
      return this.findFieldValue(filters[params.identifier][params.filterType], params.allowMultipleInstances, params.field) || { field: '', value: '' };
    }
    return { field: '', value: '' };
  }

  // FD: Todo: create methods to prove for existence of objects!
  private storeMultipleInstancesValue(params: StoreFilterParams, filters) {
    if (!filters[params.identifier][params.filterType] && this.isNotEmpty(params.value)) {
      filters[params.identifier][params.filterType] = [{ field: params.field, value: params.value }];
    } else {
      const fieldObject = this.findFieldValue(filters[params.identifier][params.filterType], params.allowMultipleInstances, params.field);
      const fieldValueIndex = this.findFieldValueIndex(filters[params.identifier][params.filterType], fieldObject);

      if (fieldValueIndex > -1) {
        if (this.isNotEmpty(params.value)) {
          filters[params.identifier][params.filterType][fieldValueIndex] = { field: params.field, value: params.value };
        } else {
          filters[params.identifier][params.filterType].splice(fieldValueIndex, 1);
        }
      } else if (this.isNotEmpty(params.value)) {
        filters[params.identifier][params.filterType].push({ field: params.field, value: params.value });
      }
    }

    this.sessionService.filters = filters;
  }

  private storeSingleInstanceValue(params: StoreFilterParams, filters: { [x: string]: { [x: string]: any; }; }) {
    if (this.isNotEmpty(params.value)) {
      filters[params.identifier][params.filterType] = { field: params.field, value: params.value };
    } else {
      if (typeof filters[params.identifier] === 'object') {
        delete filters[params.identifier][params.filterType];
      }
    }

    this.sessionService.filters = filters;
  }

  private isNotEmpty(value: any): boolean {
    if (!value) { return false; }

    if (Array.isArray(value) && value.length > 0 && value.join('') !== '') {
      return true;
    } else if (!Array.isArray(value) && value) {
      return true;
    }

    return false;
  }

  private findFieldValue(filterValues: { field: string; value: any; }[] | { field: string; value: any; }, allowMultipleInstances: boolean, field: string) {
    if (typeof filterValues !== 'object') {
      return undefined;
    }

    if (allowMultipleInstances && field) {
      filterValues = filterValues as { field: string; value: any; }[];
      return filterValues?.find(obj => obj.field === field);
    } else {
      filterValues = filterValues as { field: string; value: any; };
      return { field: filterValues?.field, value: filterValues?.value };
    }
  }

  private findFieldValueIndex(filterValues: { field: string; value: any; }[] = [], fieldObject: { field: string; value: any; }) {
    return filterValues.indexOf(fieldObject);
  }
}
