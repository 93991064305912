import { Injectable } from '@angular/core';
import { FilterService } from './filter.service';
import { SessionService } from './session.service';
import { HistoryService } from './history.service';
import { ListFilter } from '@parashift/shared/models';

export interface FieldAndValue {
  field: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ListFilterService {
  identifier = '';

  constructor(
    private sessionService: SessionService,
    private filterService: FilterService,
    private historyService: HistoryService
  ) {}

  getStoredFilters(identifier: string, filters = {}, listDefaults = false): ListFilter {
    const storedFilters = this.sessionService.filters;
    const listFilter = new ListFilter(filters, listDefaults);
    listFilter.initialFilter = JSON.stringify(this.filterService.cleanUpListFilter(listFilter));

    if (typeof storedFilters[identifier] === 'object') {
      this.identifier = identifier;
      return this.setStoredFilters(storedFilters[identifier], listFilter);
    } else if (listDefaults) {
      return this.getPageSize(listFilter);
    }
    return listFilter;
  }

  getUserDefinedFilters(tenantId: number, tab: string, filters = {}, listDefaults = false): ListFilter {
    const userDefinedFilters = this.findUserDefinedFilter(tenantId, tab);
    const listFilter = new ListFilter(filters, listDefaults);
    listFilter.initialFilter = JSON.stringify(this.filterService.cleanUpListFilter(listFilter));

    if (typeof userDefinedFilters === 'object') {
      this.identifier = '';
      return this.setStoredFilters(userDefinedFilters, listFilter);
    }
    return listFilter;
  }

  private findUserDefinedFilter(tenantId: number, tab: string) {
    const viewIdentifiers = this.sessionService.user_filters[tenantId];
    const viewKeys = viewIdentifiers && Object.keys(viewIdentifiers);
    const viewKey = viewKeys && viewKeys.find(key => viewIdentifiers[key][tab]);
    return viewKey ? viewIdentifiers[viewKey][tab]['filters'] : undefined;
  }

  private getPageSize(listFilter: ListFilter) {
    if (listFilter.page) {
      listFilter.page.size = this.sessionService.global_page_size;
    } else {
      listFilter.page = this.getDefaultPageFilter();
    }
    return listFilter;
  }

  setStoredFilters(filters: any, listFilter: ListFilter): ListFilter {
    let pageFilter;

    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        switch (key) {
          case 'id-search':
          case 'checkbox':
          case 'current-status':
          case 'tenant-users':
          case 'annotation-tenants':
          case 'search': {
            listFilter.filter = this.getFilterValue(filters[key], listFilter);
            break;
          }
          case 'selectbox': {
            listFilter.filter = this.getSelectboxValue(filters[key], listFilter);
            break;
          }
          case 'date-range': {
            listFilter.filter = this.getDateRangeValue(filters[key], listFilter);
            break;
          }
          case 'page': {
            pageFilter = filters.page;
            break;
          }
          case 'sorting': {
            listFilter.sorting = this.getSortingValue(filters.sorting);
            break;
          }
          case 'group-by': {
            listFilter.group_by = filters['group-by'].value;
            break;
          }
          case 'document-type': {
            listFilter.filter = this.getDocumentTypeValue(filters[key], listFilter);
            break;
          }
          case 'multiselectbox': {
            listFilter.filter = this.getMultiselectboxValue(filters[key], listFilter);
            break;
          }
          case 'multiselect-typeahead': {
            listFilter.filter = this.getMultiselectTypeaheadValue(filters[key], listFilter);
            break;
          }
          case 'tags-all':
          case 'tags-any': {
            listFilter.filter = this.getTagsValue(filters[key], listFilter);
            break;
          }
          case 'global-roles': {
            listFilter.filter = this.getGlobalRoleValue(filters[key], listFilter);
            break;
          }
        }
      }
    }

    // Setting page value must happen at the end after all other filters
    listFilter = this.filterService.cleanUpListFilter(listFilter);

    if (pageFilter) {
      listFilter.page = this.getPageValue(pageFilter, listFilter);
    }

    return listFilter;
  }

  private getFilterValue(filter: { forEach: (arg0: (item: any) => void) => void; field: string | number; value: any; }, listFilter: ListFilter) {
    if (Array.isArray(filter)) {
      filter.forEach(item => {
        if (!listFilter.filter[item.field]) {
          listFilter.filter[item.field] = item.value;
        }
      });
    } else if (typeof filter === 'object' && !listFilter.filter[filter.field]) {
      listFilter.filter[filter.field] = filter.value;
    }

    return listFilter.filter;
  }

  private getSelectboxValue(filter: any[], listFilter: ListFilter) {
    if (Array.isArray(filter)) {
      filter.forEach(item => {
        const fieldAndValue = this.getFieldVariants({ field: item.field, value: item.value[0] });
        listFilter.filter[fieldAndValue.field] = fieldAndValue.value;
      });
    }
    return listFilter.filter;
  }

  private getDateRangeValue(filter: { field: string; value: any; }[], listFilter: ListFilter) {
    filter.forEach((item: { field: string; value: any; }) => {
      listFilter.filter[item.field + '_range'] = item.value;
    });
    return listFilter.filter;
  }

  private getPageValue(filter: { value: number; }, listFilter: ListFilter) {
    const storedFilters = this.sessionService.filters;
    const pageData = this.getDefaultPageFilter();

    if (listFilter.page && Object.keys(listFilter.page).length > 0) {
      if (this.historyService.history && this.historyService.history.includes('/detail/')) {
        pageData.number = filter.value || 1;
      }
    }

    if (storedFilters[this.identifier].page) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      storedFilters[this.identifier].page['value'] = pageData.number;
      this.sessionService.filters = storedFilters;
    }

    return pageData;
  }

  // private getPageValue(filter: { value: number; }, listFilter: ListFilter) {
  //   let storedFilters = this.sessionService.filters;
  //   const pageData = this.getDefaultPageFilter();

  //   if (listFilter.page && Object.keys(listFilter.page).length > 0) {
  //     if (this.historyService.history && this.historyService.history.includes('/detail/')) {
  //       pageData.number = filter.value || 1;
  //     }
  //   }

  //   if (storedFilters[this.identifier]['page']) {
  //     storedFilters = storedFilters as { [identifier: string]: { [filter_type: string]: FilterSettingItem }};
  //     // storedFilters[this.identifier]['page']['value'] = pageData.number;
  //     // storedFilters[this.identifier]['page']['value'] = pageData.number;
  //     storedFilters[this.identifier]['page'].value as FilterSettingItem
  //     // storedFilters[this.identifier].page['value'] = pageData.number;
  //     this.sessionService.filters = storedFilters;
  //   }

  //   return pageData;
  // }

  private getSortingValue(filter: { value: string; }): { direction: '' | '-', field: string } {
    let direction = filter.value.substring(0, 1) as '' | '-';
    let field = filter.value;

    if (direction === '-') {
      field = filter.value.substring(1);
    } else {
      direction = '';
    }

    return { direction, field };
  }

  private getDocumentTypeValue(filter: { field: string | number; value: any[]; }, listFilter: ListFilter) {
    listFilter.filter[filter.field] = filter.value[0] || undefined;
    return listFilter.filter;
  }

  private getMultiselectboxValue(filter: any[], listFilter: ListFilter) {
    if (Array.isArray(filter)) {
      filter.forEach(item => {
        const fieldAndValue = this.getFieldVariants({ field: item.field, value: item.value[0] });
        listFilter.filter[fieldAndValue.field] = fieldAndValue.value;
      });
    }

    return listFilter.filter;
  }

  private getMultiselectTypeaheadValue(filter: any[], listFilter: ListFilter) {
    if (Array.isArray(filter)) {
      filter.forEach(item => {
        const filterValue = item.value.map((value: any) => value.key);
        const valueString = JSON.stringify(filterValue);
        listFilter.filter[item.field] = filterValue.length > 0 ? valueString : '';
      });
    }

    return listFilter.filter;
  }

  private getTagsValue(filter, listFilter) {
    if (typeof filter === 'object') {
      if (Array.isArray(filter.value)) {
        let filterValue = filter.value.map(value => value.key);
        if (filterValue[0] !== 'false' && filterValue[0] !== 'true') {
          filterValue = JSON.stringify(filterValue);
        }
        listFilter.filter[filter.field] = filterValue.length > 0 ? filterValue : '';
      }
    }
    return listFilter.filter;
  }


  private getGlobalRoleValue(filter: { field: string | number; value: any[]; }, listFilter: ListFilter) {
    listFilter.filter[filter.field] = filter.value[0] ? [filter.value[0]] : undefined;
    return listFilter.filter;
  }

  private getDefaultPageFilter() {
    return { number: 1, size: this.sessionService.global_page_size };
  }

  private getFieldVariants(fieldAndValue: FieldAndValue): FieldAndValue {
    const variant = fieldAndValue.value.split('_');
    let result = { field: '', value: '' };

    switch (variant[0]) {
      case 'blank': {
        result.field = fieldAndValue.field + '_' + 'blank';
        result.value =  variant[1];
        break;
      }
      default: {
        result = fieldAndValue;
      }
    }

    return result;
  }
}
