import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FilterConfig, FilterSetting, FilterSettingItem, HideConfig, ListFilter, SortingFilterConfig, UrlVars } from '@parashift/shared/models';
import { CurrentTenantRegister, CurrentUserService, Filter, ListFilterService, ProductRoutingService, SessionService } from '@parashift/shared/services';
import { DocumentOrBatchType } from '@parashift/shared/types';
import { deepCopy, sortableUid } from '@parashift/shared/utils';
import { FilterMapping, FilterMappingValue, FilterTabsInputs, FilterValues, UserFilter } from './filter-tabs.component';

@Injectable({
  providedIn: 'root'
})
export class FilterTabsService {

  private userFilters: { [filter_identifier: string]: UserFilter; };
  private userFiltersOrigin: { [filter_identifier: string]: UserFilter; };
  private savedFilters: Filter[] = [];
  private filterField: string;
  private filterValues: FilterValues[];
  private filterValuesOrigin: FilterValues[];
  private filterMapping: FilterMapping;
  private filterMappingOrigin: FilterMapping;
  private filterColumnConfigIdentifier: string;
  private filterColumnConfig: FilterConfig;
  private tabUserFilters: FilterSetting = {};
  private scope: string | number;

  private _filterTabsInputs$ = new BehaviorSubject<FilterTabsInputs>(undefined);
  readonly filterTabsInputs$ = this._filterTabsInputs$.asObservable();

  constructor(
    private sessionService: SessionService,
    private currentUserService: CurrentUserService,
    private currentTenantRegister: CurrentTenantRegister,
    private listFilterService: ListFilterService,
    private productRoutingService: ProductRoutingService,
  ) {}

  init(filterField: string, filterTabsFilterValues: FilterValues[], filterTabsFilterMapping: FilterMapping, filterColumnConfig: FilterConfig) {
    this.scope = this.productRoutingService.product === 'pdc' ? this.currentTenantRegister.tenant.id : this.productRoutingService.product;
    this.filterValues = undefined;
    this.filterValuesOrigin = undefined;
    this.filterMapping = undefined;
    this.filterMappingOrigin = undefined;
    this.filterColumnConfigIdentifier = undefined;
    this.userFilters = {};
    this.userFiltersOrigin = {};
    this.savedFilters = [];

    if ((!filterField && !filterTabsFilterMapping) || !filterTabsFilterValues) {
      return;
    }

    this.filterValues = [ ...filterTabsFilterValues ];
    this.filterValuesOrigin = [ ...filterTabsFilterValues ];
    this.filterMapping = { ...filterTabsFilterMapping };
    this.filterMappingOrigin = { ...filterTabsFilterMapping };
    this.filterColumnConfig = filterColumnConfig;
    this.filterColumnConfigIdentifier = filterColumnConfig.meta.identifier;
    this.mergeUserFilters();
  }

  getMappedFiltersAndSorting(defaultTab: string): { filters: { [filtername: string]: string | number | boolean }, sorting: any} {
    let filter = {};
    let sorting;

    if (this.filterMapping && Array.isArray(this.filterMapping[defaultTab])) {
      const filters = this.filterMapping[defaultTab] as Array<FilterMappingValue>;
      filters.forEach((item) => {
        filter[item.filter] = item.value;
      });
    } else if (this.filterMapping && typeof this.filterMapping[defaultTab] === 'object') {
      const filterMapping = this.filterMapping[defaultTab] as FilterMappingValue;
      filter[filterMapping.filter] = filterMapping.value;
    }

    if (defaultTab && this.userFilters && this.userFilters[defaultTab]) {
      const tabUserFilters = { ...this.userFilters[defaultTab].filters ?? {} };
      const listFilter = this.listFilterService.setStoredFilters(tabUserFilters, new ListFilter());
      filter = { ...filter, ...listFilter.filter };
      return { filters: filter, sorting: listFilter.sorting };
    }

    const sortingConfig = this.filterColumnConfig.filters.find(config => config.type === 'sorting') as SortingFilterConfig;

    if (sortingConfig && sortingConfig.config.defaultValues) {
      const sort = { sorting: { field: 'sort', value: (sortingConfig.config.defaultValues.desc ? '-' : '') + sortingConfig.config.defaultValues.field }};
      const listFilter = this.listFilterService.setStoredFilters(sort, new ListFilter());
      filter = { ...filter, ...listFilter.filter };
      sorting = listFilter.sorting;
    }

    return { filters: filter, sorting };
  }

  hideFiltersByTab(currentTab: string, hideableFilterFields: string[] = []): HideConfig {
    const hideConfig: HideConfig = {};
    hideableFilterFields.forEach(field => {
      const currentFilterValue = this.filterValues.find(item => item.value === currentTab);
      hideConfig[field] = currentFilterValue?.hideFilters?.includes(field);
    });

    return hideConfig;
  }

  getUrlVars(currentTab: string): UrlVars {
    const currentFilterValue = this.filterValues.find(item => item.value === currentTab);
    return currentFilterValue?.urlVars;
  }

  getResource(currentTab: string): DocumentOrBatchType {
    const currentFilterValue = this.filterValues.find(item => item.value === currentTab);
    return currentFilterValue?.resource || 'documents';
  }

  setTabUserFilters(currentTab: string) {
    this.savedFilters = [];
    this.tabUserFilters = {};

    if (currentTab && this.userFiltersOrigin && this.userFiltersOrigin[currentTab]) {
      this.tabUserFilters = deepCopy(this.userFiltersOrigin[currentTab].filters ?? {});
    }
  }

  getUserDefinedFilter(filterType: string, filterField: string, store = true): Filter {
    let userDefinedFilter: Filter;
    if (this.tabUserFilters && this.tabUserFilters[filterType] && Array.isArray(this.tabUserFilters[filterType])) {
      const filtersArray = this.tabUserFilters[filterType] as FilterSettingItem[];
      const filter = filtersArray.find(f => f.field === filterField);
      userDefinedFilter = filter ? { ...filter, type: filterType } : undefined;
    } else if (this.tabUserFilters && this.tabUserFilters[filterType] && typeof this.tabUserFilters[filterType] === 'object') {
      userDefinedFilter = { ...this.tabUserFilters[filterType] as FilterSettingItem, type: filterType };
    }

    userDefinedFilter && store && this.savedFilters.push(userDefinedFilter);
    return userDefinedFilter;
  }

  getUserDefinedFilters(): FilterSetting {
    return this.tabUserFilters ?? {};
  }

  removeUserFiltersFilter(filter: Filter) {
    if (this.tabUserFilters && this.tabUserFilters[filter.type] && Array.isArray(this.tabUserFilters[filter.type])) {
      const filtersArray = this.tabUserFilters[filter.type] as FilterSettingItem[];
      const index = filtersArray.findIndex(f => f.field === filter.field);
      if (index > -1) {
        filtersArray.splice(index, 1);
        this.tabUserFilters[filter.type] = filtersArray;
        if (filtersArray.length === 0) {
          delete this.tabUserFilters[filter.type];
        }
      }
    } else if (this.tabUserFilters && this.tabUserFilters[filter.type] && typeof this.tabUserFilters[filter.type] === 'object') {
      delete this.tabUserFilters[filter.type];
    }
  }

  resetFilters() {
    this.tabUserFilters = {};
  }

  addUserFilter(currentTab: string): Observable<string> {
    const filterIdentifier = sortableUid();
    const filterMappings = this.getCurrentMappedFilters(currentTab);
    const currentFilters = this.getCurrentFilters();
    const filter_values = this.getCurrentFilterValues($localize `:@@common.new:new`, filterIdentifier, currentTab);

    const userFilter: UserFilter = {
      filter_mapping: { [filterIdentifier]: filterMappings },
      filter_values,
      filters: currentFilters
    };

    const userFilters = { ...this.sessionService.user_filters };
    const scopedUserFilters = userFilters[this.scope] ?? {} ;
    scopedUserFilters[this.filterColumnConfigIdentifier] = scopedUserFilters[this.filterColumnConfigIdentifier] ?? {};
    scopedUserFilters[this.filterColumnConfigIdentifier][filterIdentifier] = userFilter;
    userFilters[this.scope] = scopedUserFilters;
    this.sessionService.user_filters = userFilters;
    this.currentUserService.saveUserFilters(userFilters);
    this.mergeUserFilters();
    return of(filterIdentifier);
  }

  saveFilterTitle(tab: string, filterValue: FilterValues) {
    const userFilters = { ...this.sessionService.user_filters };
    const scopedUserFilters = userFilters[this.scope] ?? {} ;
    const viewFilters = scopedUserFilters[this.filterColumnConfigIdentifier];
    if (viewFilters && viewFilters[tab]) {
      const newValue = { ...filterValue };
      delete newValue.userDefined;
      scopedUserFilters[this.filterColumnConfigIdentifier][tab].filter_values = newValue;
      userFilters[this.scope] = scopedUserFilters;
      this.sessionService.user_filters = { ...userFilters };
      this.currentUserService.saveUserFilters(userFilters);
      this.mergeUserFilters();
    }
  }

  deleteUserFilter(tab: string) {
    const userFilters = { ...this.sessionService.user_filters };
    const scopedUserFilters = userFilters[this.scope] ?? {} ;
    const viewFilters = scopedUserFilters[this.filterColumnConfigIdentifier];
    if (viewFilters && viewFilters[tab]) {
      delete scopedUserFilters[this.filterColumnConfigIdentifier][tab];
      userFilters[this.scope] = scopedUserFilters;
      this.sessionService.user_filters = { ...userFilters };
      this.currentUserService.saveUserFilters(userFilters);
      this.mergeUserFilters();
      return true;
    }
    return false;
  }

  private mergeUserFilters() {
    if (this.filterColumnConfigIdentifier) {

      const userFilters = { ...this.sessionService.user_filters };
      const scopedUserFilters = { ...(userFilters[this.scope] ?? {}) };
      const viewFilters = { ...(scopedUserFilters[this.filterColumnConfigIdentifier] ?? {}) };
      const filterKeys = Object.keys(viewFilters);
      const filterMappings = {};
      const filterValues = [];
      filterKeys.forEach(key => {
        filterMappings[key] = scopedUserFilters[this.filterColumnConfigIdentifier][key].filter_mapping[key];
        const filterValue = { ...scopedUserFilters[this.filterColumnConfigIdentifier][key].filter_values };
        filterValue.userDefined = true;
        filterValues.push(filterValue);
      });

      this.filterMapping = { ...this.filterMappingOrigin, ...filterMappings };
      this.filterValues = [...this.filterValuesOrigin, ...filterValues];
      this.userFilters = { ...viewFilters };
      this.userFiltersOrigin = deepCopy(viewFilters);
    }

    this._filterTabsInputs$.next({
      filterField: this.filterField,
      filterValues: this.filterValues,
      filterMapping: this.filterMapping,
      filterColumnConfigIdentifier: this.filterColumnConfigIdentifier
    });
  }

  private getCurrentFilterValues(label: string, filterIdentifier: string, currentTab: string): FilterValues {
    const currentFilterValue = this.filterValues.find(item => item.value === currentTab);
    const filterValue: FilterValues = { label, value: filterIdentifier, resource: currentFilterValue?.resource || 'documents' };

    if (currentFilterValue?.roles) {
      filterValue.roles = currentFilterValue.roles;
    }

    if (currentFilterValue?.permissions) {
      filterValue.permissions = currentFilterValue.permissions;
    }

    if (currentFilterValue?.urlVars) {
      filterValue.urlVars = currentFilterValue.urlVars;
    }

    if (currentFilterValue?.hideFilters) {
      filterValue.hideFilters = currentFilterValue.hideFilters;
    }

    return filterValue;
  }

  private getCurrentFilters(): FilterSetting {
    const filters = { ...(this.sessionService.filters ?? {}) };
    const viewFilters = filters[this.filterColumnConfigIdentifier] ?? {};
    const filterKeys = Object.keys(viewFilters);
    let currentFilters = { ...this.getUserDefinedFilters() };

    filterKeys.forEach(key => {
      if (key === 'page') {
        return;
      }

      let filter = viewFilters[key];
      if (filter && Array.isArray(filter) && filter.length > 0) {
        const multiInstanceFilters = [];
        filter.forEach(filterItem => {
          if (filterItem.value && Array.isArray(filterItem.value) && filterItem.value.length > 0) {
            multiInstanceFilters.push(filterItem);
          } else if (filterItem.value && !Array.isArray(filterItem.value)) {
            multiInstanceFilters.push(filterItem);
          }
        });

        currentFilters = this.mergeMultiInstancesFilters(key, multiInstanceFilters, currentFilters);

      } else if (filter && typeof filter === 'object') {
        filter = filter as FilterSettingItem;
        let singleInstanceFilter;
        if (filter.value && Array.isArray(filter.value) && filter.value.length > 0) {
          singleInstanceFilter = filter;
        } else if (filter.value) {
          singleInstanceFilter = filter;
        }
        if (singleInstanceFilter) {
          currentFilters[key] = singleInstanceFilter;
        }
      }
    });

    return currentFilters;
  }

  private mergeMultiInstancesFilters(key: string, multiInstanceFilters: FilterSettingItem[], currentFilters: FilterSetting): FilterSetting {
    const newFilters = [];
    multiInstanceFilters.forEach(mf => {
      const currentFilter = currentFilters[key] as FilterSettingItem[];
      if (currentFilter && currentFilter.length > 0) {
        const index = currentFilter.findIndex(f => f.field === mf.field);
        if (index > -1) {
          currentFilter[index] = mf;
          currentFilters[key] = currentFilter;
        } else {
          newFilters.push(mf);
        }
      } else {
        newFilters.push(mf);
      }
    });

    if (newFilters.length > 0) {
      currentFilters[key] = currentFilters[key] || [];
      currentFilters[key] = [...currentFilters[key] as FilterSettingItem[], ...newFilters];
    }

    return currentFilters;
  }

  private getCurrentMappedFilters(currentTab: string): FilterMappingValue[] {
    if (currentTab) {
      if (this.filterMapping && Array.isArray(this.filterMapping[currentTab])) {
        return this.filterMapping[currentTab] as FilterMappingValue[];
      } else if (this.filterMapping && typeof this.filterMapping[currentTab] === 'object') {
        return [this.filterMapping[currentTab]] as FilterMappingValue[];
      } else {
        return [{ filter: this.filterField, value: currentTab }];
      }
    }
    return undefined;
  }
}
